import React from "react";
import { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Request a Demo",
  heading = (
    <>
      Experience the Power of Efficiency.{" "}
      <span tw="text-primary-500">Request a Free Demo</span>
      <wbr />
    </>
  ),
  description = "See how our software solutions can streamline your operations and boost your bottom line.",
  submitButtonText = "Send",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [fields, setFields] = useState({
    email: "",
    name: "",
    subject: "",
    message: "",
  });
  const [message, setMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const script = document.createElement("script");
  const submitForm = (e) => {
    e.preventDefault();

    const url =
      `https://nuvolayer.us13.list-manage.com/subscribe/post-json?` +
      `u=4ad19597548ca43229c46deab&id=63ab143db0&c=mcCallback&` +
      `MERGE0=${encodeURIComponent(fields.email)}&` +
      `MERGE1=${encodeURIComponent(fields.name)}&` +
      `MERGE2=${encodeURIComponent(fields.subject)}&` +
      `MERGE3=${encodeURIComponent(fields.message)}`;

    script.src = url;
    document.body.appendChild(script);
  };

  // Clear all fields after form submission
  useEffect(() => {
    if (message) {
      // Clear all fields after form submission
      setFields({
        email: "",
        name: "",
        subject: "",
        message: "",
      });
    }
  }, [message]);

  window.mcCallback = (response) => {
    console.log(response);
    if (response.result === "success") {
      setMessage("Thank you for contacting us, we'll be in touch shortly.");
    } else {
      setMessage(response.msg);
    }
    document.body.removeChild(script);
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form
              onSubmit={submitForm}
              className="mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0"
            >
              {message && <span>{message}</span>}
              <Input
                type="email"
                name="email"
                autocapitalize="off"
                autocorrect="off"
                size="25"
                placeholder="Your Email Address"
                value={fields.email}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                value={fields.name}
                onChange={handleChange}
              />
              <Input
                type="text"
                name="subject"
                placeholder="Subject"
                value={fields.subject}
                onChange={handleChange}
              />
              <Textarea
                name="message"
                placeholder="Your Message Here"
                value={fields.message}
                onChange={handleChange}
              />
              <SubmitButton>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
