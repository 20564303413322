import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithSteps.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";

import OilGasImage from "images/context/oil-gas.jpg";
import VisionImage from "images/context/vision.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Us</Subheading>}
        heading="Optimizing Oil & Gas Transportation"
        description="At NuvoLayer Technologies, we are passionate about driving efficiency and innovation in the oil & gas transportation industry. We understand the complex challenges faced by companies in this sector, and we are dedicated to developing software solutions that empower them to streamline operations, optimize logistics, and make data-driven decisions. Our team of industry experts combines extensive knowledge with a commitment to creating user-friendly, powerful software that helps companies achieve peak performance and deliver exceptional results."
        hasButton={false}
        imageSrc={OilGasImage}
      />
      <MainFeature2
        subheading={<Subheading>Vision & Mission</Subheading>}
        heading="Our Vision & Mission Statements."
        imageSrc={VisionImage}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="Powering Efficiency and Growth"
        description="We believe these core values guide us in creating innovative software solutions for your success."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Client-Centricity",
            description:
              "We are dedicated to understanding your unique challenges and goals in the oil & gas transportation industry.  Your feedback is invaluable, and it fuels our passion to continually develop and improve our software solutions to perfectly meet your evolving needs.",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Data-Driven Decisions",
            description:
              "We believe in the power of data.  Our software is designed to capture and analyze real-time and historical data, providing you with the insights you need to make informed decisions that optimize operations, improve efficiency, and maximize profitability.",
          },
          {
            imageSrc: ReliableIconImage,
            title: "Continuous Improvement",
            description:
              "We share your commitment to progress.  Our team is dedicated to continuous improvement, relentlessly seeking new ways to enhance our software's functionality, user experience, and security to ensure you have the most advanced tools at your disposal.",
          },
        ]}
        linkText=""
      />

      <Footer />
    </AnimationRevealPage>
  );
};
