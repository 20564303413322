import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/MiniCenteredFooter.js";
// import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
// import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
// import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";

import FleetManagementFAQs from "components/faqs/SimpleWithSideImage.js";
import LastMileDeliveryFAQs from "components/faqs/SimpleWithSideImageTextLeft.js";
import DispatchManagementFAQs from "components/faqs/SimpleWithSideImage.js";

import fleetImage from "../images/context/fleet.png";
import deliveriesImage from "../images/context/deliveries.png";
import dispatchImage from "../images/context/dispatch.png";

import ApplicationStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// Fleet Management FAQs
const fleetManStepOne = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>
      Automate reminders for expiring documents and equipment inspections.
    </li>
    <li>Gain real-time visibility into vehicle health and performance.</li>
  </ul>
);

const fleetManStepTwo = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>Streamline driver changeovers with digital tools.</li>
    <li>Monitor driver behavior and identify areas for improvement.</li>
  </ul>
);

const fleetManStepThree = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>Electronically track and manage driver certifications and licenses.</li>
    <li>Generate detailed reports for audits and safety inspections.</li>
  </ul>
);

// Last Mile Delivery FAQs
const lastMileStepOne = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>
      Streamline loading and offloading processes to maximize the number of
      deliveries completed each day.
    </li>
    <li>
      Improve driver efficiency with optimized routes and real-time traffic
      updates.
    </li>
  </ul>
);

const lastMileStepTwo = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>
      Ensure precise data collection at depots and stations, eliminating human
      error and discrepancies.
    </li>
    <li>
      Gain complete transparency into your product movement, fostering trust
      with your clients.
    </li>
  </ul>
);

const lastMileStepThree = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>
      Utilize driver sign-off for loaded and offloaded products, providing a
      clear chain of custody.
    </li>
    <li>
      Improve driver performance and customer satisfaction with transparent
      delivery processes.
    </li>
  </ul>
);

const lastMileStepFour = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>
      Effortlessly manage station tank levels and calibration charts for smooth
      operations.
    </li>
    <li>
      Optimize delivery routes based on station distances, minimizing travel
      time and fuel costs.
    </li>
  </ul>
);

// Dispatch Management FAQs
const dispatchManStepOne = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>
      Schedule and dispatch orders from a single, user-friendly interface,
      streamlining workflows.
    </li>
    <li>
      Allocate compartment space efficiently to optimize truck capacity and
      minimize wasted resources.
    </li>
  </ul>
);

const dispatchManStepTwo = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>
      Monitor the location and status of every delivery truck, allowing for
      dynamic route adjustments and improved communication.
    </li>
    <li>
      Proactively address delivery issues and proactively inform customers of
      any potential delays.
    </li>
  </ul>
);

const dispatchManStepThree = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>
      Easily divert orders to ensure customer satisfaction and handle split
      deliveries with ease.
    </li>
    <li>
      Adapt to unexpected changes and maintain efficient operations even during
      peak hours.
    </li>
  </ul>
);

const dispatchManStepFour = (
  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
    <li>
      Map orders to optimized routes, minimizing travel time and fuel
      consumption.
    </li>
    <li>
      Share schedules and real-time updates with transporters for seamless
      communication and collaboration.
    </li>
  </ul>
);

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            Transport Management
            {/* <HighlightedText>Service.</HighlightedText> */}
          </>
        }
        description="Master Your Logistics with Powerful Transport Management. Eliminate inefficiencies, optimize routes, and gain real-time visibility into your entire operation."
      />
      <FleetManagementFAQs
        subheading={<Subheading>Fleet Management</Subheading>}
        heading={
          <>
            Unleash <HighlightedText>Peak Performance</HighlightedText> with
            Fleet Management
          </>
        }
        description={
          <>
            Gain real-time insights, optimize operations, and ensure regulatory
            compliance.
          </>
        }
        faqs={[
          {
            question: "Reduce downtime and maintenance costs",
            answer: fleetManStepOne,
          },
          {
            question: "Empower drivers and improve safety",
            answer: fleetManStepTwo,
          },
          {
            question: "Ensure regulatory compliance",
            answer: fleetManStepThree,
          },
        ]}
        imageSrc={fleetImage}
        textOnLeft={true}
      />
      <LastMileDeliveryFAQs
        subheading={<Subheading>Last Mile Delivery</Subheading>}
        heading={
          <>
            Delivering Efficiency, Every Gallon:{" "}
            <HighlightedText>Last Mile Mastery</HighlightedText>
          </>
        }
        description={
          <>
            Reduce delivery times, increase efficiency, and ensure accurate
            product delivery.
          </>
        }
        faqs={[
          {
            question: "Faster turnarounds, more deliveries",
            answer: lastMileStepOne,
          },
          {
            question: "Accuracy you can trust",
            answer: lastMileStepTwo,
          },
          {
            question: "Enhanced accountability",
            answer: lastMileStepThree,
          },
          {
            question: "Seamless station management",
            answer: lastMileStepFour,
          },
        ]}
        imageSrc={deliveriesImage}
        textOnLeft={true}
      />
      <DispatchManagementFAQs
        subheading={<Subheading>Dispatch Management</Subheading>}
        heading={
          <>
            Smart Scheduling, Streamlined Deliveries:{" "}
            <HighlightedText>Powerful Dispatch</HighlightedText>
          </>
        }
        description={
          <>
            Gain complete control over your dispatch operations, ensuring
            efficient deliveries and exceptional customer service.
          </>
        }
        faqs={[
          {
            question: "Centralized order management",
            answer: dispatchManStepOne,
          },
          {
            question: "Real-time tracking and visibility",
            answer: dispatchManStepTwo,
          },
          {
            question: "Flexibility at your fingertips",
            answer: dispatchManStepThree,
          },
          {
            question: "Route optimization and collaboration",
            answer: dispatchManStepFour,
          },
        ]}
        imageSrc={dispatchImage}
        textOnLeft={true}
      />

      <ApplicationStats />

      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question:
              "Is your software easy to integrate with existing systems?",
            answer:
              "Absolutely! We offer a range of integration options to ensure seamless connection with your existing dispatch, fleet management, and other relevant software systems. Our team can also assist you with the integration process.",
          },
          {
            question:
              "How does your software improve driver performance and safety?",
            answer:
              "In several ways! We provide features like real-time driver monitoring, automated reminders for inspections and certifications, and route optimization to minimize fatigue. This combination helps ensure driver safety and promotes responsible driving habits.",
          },
          {
            question: "What kind of training and support do you offer?",
            answer:
              "We offer comprehensive training resources and ongoing support to ensure your team can leverage the full potential of our software. We have a dedicated support team ready to answer your questions.",
          },
          {
            question:
              "Do you offer different pricing plans to fit my company's needs?",
            answer:
              "Yes, we do! We understand that companies have varying requirements. We offer flexible pricing plans based on features, number of users, and the size of your fleet. We can tailor a plan that fits your specific needs and budget.",
          },
          {
            question:
              "Can you guarantee improved fuel efficiency or reduced delivery times?",
            answer:
              "While we cannot guarantee specific results, our software empowers you to make data-driven decisions that can significantly impact fuel efficiency and delivery times. Features like route optimization, real-time tracking, and driver performance monitoring can lead to measurable improvements in these areas. Many of our satisfied customers have reported experiencing such benefits after implementing our software.",
          },
        ]}
      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
