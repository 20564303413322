import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

export default () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);

  // Create a dynamic script element to load Mailchimp API with JSONP
  const script = document.createElement("script");

  const submitForm = (e) => {
    e.preventDefault();

    const url =
      "https://nuvolayer.us13.list-manage.com/subscribe/post-json?u=4ad19597548ca43229c46deab&id=63ab143db0&c=mcCallback&EMAIL=" +
      encodeURIComponent(email);

    script.src = url;
    document.body.appendChild(script);
  };

  // Define a callback function that Mailchimp will call after the subscription attempt
  window.mcCallback = (response) => {
    if (response.result === "success") {
      setMessage("Thank you for requesting, we'll be in touch shortly.");
    } else {
      setMessage(response.msg);
    }
    // Remove the script element from the DOM
    document.body.removeChild(script);
  };

  useEffect(() => {
    if (message) {
      setEmail("");
      const timeoutId = setTimeout(() => {
        setMessage(null);
      }, 5000); // 5 seconds in milliseconds
      return () => clearTimeout(timeoutId); // Cleanup function
    }
  }, [message]);

  return (
    <Actions>
      <form onSubmit={submitForm}>
        <input
          type="email"
          name="MERGE0"
          id="MERGE0"
          placeholder="Your E-mail Address"
          autocapitalize="off"
          autocorrect="off"
          size="25"
          onChange={(e) => setEmail(e.target.value)}
        />
        <button>Request Demo</button>
      </form>
      {message && (
        <span
          style={{
            position: "absolute",
            left: "32px",
            marginTop: "14px",
            color: "#a9aec0",
          }}
        >
          {message}
        </span>
      )}
    </Actions>
  );
};
